<template>
    <div class="index">
        <div class="banner">
            <Banner :type="'MAIN'" :height="700"></Banner>
        </div>
        <div class="goods">
            <h1>产品服务</h1>
            <div class="list">
                <div class="item">
                    <img src="./image/index-item1.png" alt />
                    <span>社交电商</span>
                    <p>专注社交电商分销系统开发 佣金秒结算</p>
                </div>
                <div class="item">
                    <img src="./image/index-item2.png" alt />
                    <span>供应链</span>
                    <p>零库存，一键代发 轻松做电商</p>
                </div>
                <div class="item">
                    <img src="./image/index-item3.png" alt />
                    <span>系统服务商</span>
                    <p>京东、微信、支付宝优质系统服务商</p>
                </div>
                <div class="item">
                    <img src="./image/index-item4.png" alt />
                    <span>信息化</span>
                    <p>帮助企业数字化转型优化经营数据指标</p>
                </div>
            </div>
        </div>

        <div class="about">
            <h1>关于我们</h1>
            <div class="content">
                <img src="./image/guanyu.png" alt />
                <div class="profile">
                    <p>山西趣拓客科技有限公司</p>
                    <p
                        style="margin:40px 0 83px;"
                    >山西趣拓客科技有限公司主营业务，以自研发的云平 台为支撑是一家提供互联网推广、互联网营销 策划的解决方案供应商</p>
                    <router-link to="/about">了解更多 →</router-link>
                </div>
            </div>
        </div>

        <div class="news">
            <h1>新闻资讯</h1>
            <div class="content">
                <div class="left fl">
                    <template :key="index" v-for="(item, index) in data.news">
                        <div class="item">
                            <div class="title">
                                <b>{{item.title}}</b>
                                <span>{{item.newsDate}}</span>
                            </div>
                            <p>{{item.content}}</p>
                        </div>
                        <div class="line" v-if="index < data.news.length-1"></div>
                    </template>

                    <div class="button" @click="handle_to_news()">查看更多</div>
                </div>
                <img class="fr" src="./image/xinwen.png" alt />
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./index.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import Banner from "../../components/banner";

export default {
    setup(props) {
        let router = useRouter();
        let { proxy } = getCurrentInstance();
        const data = reactive({
            news: []
        });

        const handle_to_about = () => {
            router.push({
                path: "/about"
            });
        };

        const handle_to_news = () => {
            router.push({
                path: "/news"
            });
        };

        onMounted(() => {
            proxy.$axios.get("/new/main").then(res => {
                data.news = res.data;
            });
        });

        return {
            handle_to_about,
            handle_to_news,
            data
        };
    },
    components: {
        Banner
    }
};
</script>
